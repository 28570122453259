import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'styled-bootstrap-grid2'
import ContainerText from '../../components/container_text'
import SEO from '../../components/seo'
import background from '../../images/backgrounds/shippingreturns.jpg'
import ShopifyPage from '../../components/shopify_page'

const Title = styled.h3`
  font-size: 13px;
  padding-bottom: 2rem;
`

const bgStyle = { backgroundImage: `url(${background})`, backgroundSize: 'cover', 'backgroundRepeat': 'no-repeat' };

const IndexPage = () => (
  <div style={bgStyle}>
    <SEO title="Shipping & Returns" keywords={[`Shipping`, `Returns`,`Theodora`, `Warre`]} />
    <ContainerText top longpage>
      <Container>
        <Row>
          <Col xl="6" lg="6" md="12" sm="12" xs="12">
            <Title>Shipping</Title>
            <ShopifyPage handle="shipping" />
          </Col>
          <Col xl="6" lg="6" md="12" sm="12" xs="12">
           <Title>Returns</Title>
           <ShopifyPage handle="returns" />
          </Col>
        </Row>
      </Container>
    </ContainerText>
  </div>
)

export default IndexPage
